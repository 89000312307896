import type { BaseType } from 'src/types/base.type';
import type { FlatItem } from 'src/types/elements.type';
import type { UploadFile } from 'antd';
import type { AccessFormAdd, IAccessForm } from 'src/types/helpdesk/accessForm.type';
import type { IApprovalLog } from 'src/types/helpdesk/approvalLog.type';
import type { IApprovalProcess } from 'src/types/helpdesk/approvalProcess.type';
import type { EmailFormAdd, IEmailForm } from 'src/types/helpdesk/emailForm.type';
import type { IHandleProcess } from 'src/types/helpdesk/handleProcess.type';
import type { IManpowerSupplyForm, ManpowerSupplyFormAdd } from 'src/types/helpdesk/manpowerSupplyForm.type';
import type { IAssignLog, StatusType } from 'src/types/helpdesk/processTree.type';
import type { ITicketCategory } from 'src/types/helpdesk/ticketCategory.type';
import type { ITicketSolution } from 'src/types/helpdesk/ticketSolution.type';
import type { IApproval } from 'src/types/hr_ticket/approval.type';
import type {
  IEmployeeLong,
  IEmployeeShort,
  IEmployeeWithDepartment,
  UserInfo
} from 'src/types/master_data/employee.type';

export interface ITicket extends BaseType {
  id: string;
  ticketCode: string;
  userOwner: string;
  title: string;
  parentId: string;
  departmentId: number;
  receiveDepartmentId: number;
  ticketCategoryGroupId: number;
  ticketCategoryId: number;
  ticketTypeId: number;
  ticketStatusId: number;
  isApproval: boolean;
  isConfirmHandle?: Boolean;
  isFinish?: Boolean;
  acceptedDate?: Date;
  rejectDate?: Date;
  confirmHandleDate?: Date;
  finishedDate?: Date;
  createdBy: number;
  updatedBy?: number;
  isActive: boolean;
  remark: string;
  cause: string;
  personRequestId: number;
  personRequest?: IEmployeeShort;
  forPersonId: number;
  forPerson?: IEmployeeShort;
  ownerObj?: IEmployeeWithDepartment;
  date: Date;
  topicId: string;
  priorityLevelId: number;
  assignTaskId: number;
  productionRequestCode: string;

  isNotCompleted?: boolean;
  countNotCompleted: number;
  departmentHandle: string;
  departmentHandleId: number;

  reason: FlatItem;
  noteReject: string;
  noteComplete: string;
  isHandleComplete: boolean;

  isAvailableCancel: boolean;
  isAvailableUpdate: boolean;

  currentApprovalStep: number;
  versionOfApproval: number;

  approvalStatus?: number;
  actionDate?: Date;
  step?: number;
  isWaiting?: boolean;
  stepWaiting?: number;
  availableUpdate?: boolean;

  parent?: ITicket;
  ticketCategory: ITicketCategory;
  ticketCategoryGroup: FlatItem;
  ticketType: FlatItem;
  ticketStatus: FlatItem;
  createdByObj: IEmployeeWithDepartment;
  updatedByObj?: FlatItem;
  ticketFiles: ITicketFile[];
  emailForms: IEmailForm[];
  accessForms: IAccessForm[];
  ticketForms: ITicketForm[];
  formId: number;
  manpowerSupplyForms: IManpowerSupplyForm[];
  handlerObj: FlatItem;
  handlerId: number;
  approvalProcess: IApprovalProcess;
  approvalProcessId: number;
  ticketSolutions: ITicketSolution[];
  handleProcess: IHandleProcess;
  assignLogs?: IAssignLog[];
  approvalLogs: IApprovalLog[];
  ticketRemarks: ITicketRemark[];

  accepts: FlatItem[];
  assigns: FlatItem[];

  waitingNextPosition: string;
  waitingNextApprovers: IEmployeeShort[];
  handleLogs: IHandleLog[];
  departmentRequest: FlatItem;
  receiveDepartment: FlatItem;

  permissionAssign: boolean;
  permissionApproveSolution: boolean;
  permissionAccept: boolean;
}

export type ITicketShort = Pick<
  ITicket,
  | 'id'
  | 'createdBy'
  | 'createdByObj'
  | 'createdDate'
  | 'lastModifiedDate'
  | 'userOwner'
  | 'remark'
  | 'ticketCategory'
  | 'ticketCode'
  | 'ticketStatus'
  | 'ticketStatusId'
  | 'title'
>;

export interface ITicketRemark extends BaseType {
  id: string;
  remark: string;
  ticketId: string;
}

export type TicketRemarkAdd = Partial<ITicketRemark>;

export interface ITicketConfirm extends ITicket {
  approvalStatus?: ApprovalStatus;
  actionDate?: Date;
  step?: number;
  isWaiting: boolean;
}

export interface ITicketApproval extends ITicket {
  approvalStatus?: ApprovalStatus;
  actionDate?: Date;
  step: number;
  isWaiting: boolean;
  stepWaiting: number;
  availableUpdate?: boolean;
}

export interface ITicketHandle extends ITicket {
  isNotCompleted?: boolean;
  countNotCompleted: number;
}

export interface ITicketMyHandle extends ITicket {
  assignedDate?: Date;
}

export type TicketAdd = Partial<
  Pick<
    ITicket,
    | 'priorityLevelId'
    | 'ticketCategoryGroupId'
    | 'productionRequestCode'
    | 'ticketCategoryId'
    | 'date'
    | 'remark'
    | 'cause'
    | 'ticketFiles'
  > & {
    accessForms: Partial<AccessFormAdd>[];
    emailForms: Partial<EmailFormAdd>[];
    manpowerSupplyForms: Partial<ManpowerSupplyFormAdd>[];
    ticketForms: Partial<ITicketForm>[];
  }
>;

export interface ITicketForm {
  id?: number;
  ticketId?: string;
  rowId: string;
  order: number;
  label: string;
  value: string;
}

export interface ITicketFile {
  id: string;
  ticketId: string;
  filePath: string;
  originalName: string;
  contentType: string;
  fileContents: string;
}

export type TicketFileAdd = Pick<ITicketFile, 'filePath' | 'originalName'>;

export enum ApprovalStatus {
  Waiting = 0,
  Approve = 1,
  Reject = 2
}

export enum TicketStatus {
  New = 1,
  ApprovedRequest = 2,
  AcceptReceiveRequest = 3,
  Processing = 4,
  HandleCompleted = 5,
  Finished = 6,
  Reject = 7,
  Canceled = 8,
  HandleHistory = 9
}

export interface TicketStatusCount {
  new: number;
  pending: number;
  approvedRequest: number;
  approved: number;
  acceptReceiveRequest: number;
  processing: number;
  handleCompleted: number;
  finished: number;
  rejected: number;
  canceled: number;
  confirmed: number;
  rejectedConfirm: number;

  completed: number;
  notCompleted: number;
}
export interface ApprovalStatusCount {
  totalTicket: number;
  waiting: number;
  approved: number;
  rejected: number;
  handling: number;
  waitingConfirm: number;
  finished: number;
  synchronized: number;
}

export type UploadFileType = Omit<UploadFile<File>, 'originFileObj'> & {
  originFileObj: File;
};

export interface IHandleLog {
  id: number;
  ticketId: string;
  personId: number;
  reasonId: number;
  approvalStatus: number;
  reason: string;
  logAt: Date;
  employee: IEmployeeShort;
  createdDate: Date;
  lastModifiedDate: Date;
}

export type IAcceptLog = Partial<
  IHandleLog & {
    statusType: StatusType;
    employees: FlatItem[];
  }
>;

export interface IApprovalProcessDetailByCreated {
  approvalLevel: number;
  approvalNumberStep: number;
  employee: UserInfo[];
  employeeId: number;
  isAutoPickPosition: boolean;
  position: FlatItem;
  positionId: number;
  stepName: string;
}

export interface IApprovalProcessDetail {
  approvalLevel: number;
  employees: IEmployeeLong[] | undefined;
  employeeId: number | undefined;
  position: FlatItem | undefined;
  stepName: string;
  approval?: Partial<IApprovalLog>;
}

export interface IApprovalProcessDetailV2 {
  approvalLevel: number;
  employees: UserInfo[] | undefined;
  employeeId: number | undefined;
  position: FlatItem | undefined;
  stepName: string;
  approval?: Partial<IApproval>;
}

export interface ITicketParams {
  sequenceStatus: string;
  page: number;
  pageSize: number;
  orderBy?: string;
  ticketCategoryId?: number;
  approvalStatus?: number | undefined;
  ticketStatusId?: number | undefined;
  searchCondition?: string | undefined;
  departmentHandleId?: number | undefined;
  departmentRequestId?: number | undefined;
  fDate?: Date | string | undefined;
  tDate?: Date | string | undefined;
}

export type HandleForRefParams = {
  page: number;
  pageSize: number;
  orderBy?: string;
};

import { useQueries } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import { createContext, useEffect, useState, type Dispatch, type SetStateAction } from 'react';
import { getWebConfigs } from 'src/apis/helpdesk/webConfig.api';
import { getResourcesByParent } from 'src/apis/master_data/resource.api';
import { checkAdmin } from 'src/apis/master_data/user.api';
import { WebConfigEn } from 'src/constants/webConfig.enum';
import i18n from 'src/translation/i18n';
import type { IEmployee, UserInfo } from 'src/types/master_data/employee.type';
import type { ResourceShort } from 'src/types/master_data/resource.type';
import type { IRole } from 'src/types/master_data/role.type';
import type { IResourceFullAccessDetail } from 'src/types/master_data/userResource.type';
import type { IDocumentField } from 'src/types/psm/documentField.type';

import type { Language, Theme, WebConfigAdd } from 'src/types/webConfig.type';
import { getAccessTokenFromLS } from 'src/utils/auth';
import { buildHierarchy } from 'src/utils/collection';

interface AppContextInterface {
  ticketSelected: string | undefined;
  setTicketSelected: Dispatch<SetStateAction<string | undefined>>;
  openTicketManagement: boolean;
  setOpenTicketManagement: Dispatch<SetStateAction<boolean>>;
  openAppMenu: boolean;
  setOpenAppMenu: Dispatch<SetStateAction<boolean>>;
  isSidebarCollapsed: boolean;
  setIsSidebarCollapsed: Dispatch<SetStateAction<boolean>>;
  isTicketDetailCollapsed: boolean;
  setIsTicketDetailCollapsed: Dispatch<SetStateAction<boolean>>;
  isDetailSidebarCollapsed: boolean;
  setIsDetailSidebarCollapsed: Dispatch<SetStateAction<boolean>>;
  theme: Theme | undefined;
  setTheme: Dispatch<SetStateAction<Theme | undefined>>;
  language: Language | undefined;
  setLanguage: Dispatch<SetStateAction<Language | undefined>>;
  userInfo: UserInfo | null;
  setUserInfo: Dispatch<SetStateAction<UserInfo | null>>;
  userInfoQr: IEmployee | undefined;
  setUserInfoQr: Dispatch<SetStateAction<IEmployee | undefined>>;
  resources: IResourceFullAccessDetail[] | undefined;
  setResources: Dispatch<SetStateAction<IResourceFullAccessDetail[] | undefined>>;
  resourcePermissionLoading: boolean | undefined;
  setResourcePermissionLoading: Dispatch<SetStateAction<boolean | undefined>>;
  roles: IRole[] | undefined;
  setRoles: Dispatch<SetStateAction<IRole[] | undefined>>;
  isUserAdmin: boolean | undefined;
  setIsUserAdmin: Dispatch<SetStateAction<boolean | undefined>>;
  isOpenFilterDrawer: boolean;
  setIsOpenFilterDrawer: Dispatch<SetStateAction<boolean>>;
  documentField: IDocumentField | undefined;
  setDocumentField: Dispatch<SetStateAction<IDocumentField | undefined>>;
  documentFieldLoading: boolean;
  setDocumentFieldLoading: Dispatch<SetStateAction<boolean>>;
  rootResource: ResourceShort | undefined;
  setRootResource: Dispatch<SetStateAction<ResourceShort | undefined>>;
  rootResources: ResourceShort[];
  setRootResources: Dispatch<SetStateAction<ResourceShort[]>>;
  currentResource: ResourceShort | undefined;
  setCurrentResource: Dispatch<SetStateAction<ResourceShort | undefined>>;
}

const userInfoJson = localStorage.getItem('userInfo');
const isAdminJson = localStorage.getItem('isAdmin');

const initialAppContext: AppContextInterface = {
  ticketSelected: undefined,
  setTicketSelected: () => null,
  openTicketManagement: false,
  setOpenTicketManagement: () => null,
  openAppMenu: false,
  setOpenAppMenu: () => null,
  isSidebarCollapsed: false,
  setIsSidebarCollapsed: () => null,
  isDetailSidebarCollapsed: true,
  setIsDetailSidebarCollapsed: () => null,
  isTicketDetailCollapsed: true,
  setIsTicketDetailCollapsed: () => null,
  theme: 'light',
  setTheme: () => null,
  language: 'vi',
  setLanguage: () => null,
  userInfo: userInfoJson ? JSON.parse(userInfoJson) : null,
  setUserInfo: () => null,
  userInfoQr: undefined,
  setUserInfoQr: () => null,
  resources: undefined,
  setResources: () => null,
  resourcePermissionLoading: undefined,
  setResourcePermissionLoading: () => null,
  roles: [],
  setRoles: () => null,
  isUserAdmin: isAdminJson ? JSON.parse(isAdminJson) : null,
  setIsUserAdmin: () => null,
  isOpenFilterDrawer: false,
  setIsOpenFilterDrawer: () => null,
  documentField: undefined,
  setDocumentField: () => null,
  documentFieldLoading: false,
  setDocumentFieldLoading: () => null,
  rootResource: undefined,
  setRootResource: () => null,
  currentResource: undefined,
  setCurrentResource: () => null,
  rootResources: [],
  setRootResources: () => null
};

export const AppContext = createContext<AppContextInterface>(initialAppContext);

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [ticketSelected, setTicketSelected] = useState<string | undefined>(initialAppContext.ticketSelected);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(initialAppContext.isSidebarCollapsed);
  const [isTicketDetailCollapsed, setIsTicketDetailCollapsed] = useState(initialAppContext.isTicketDetailCollapsed);
  const [isDetailSidebarCollapsed, setIsDetailSidebarCollapsed] = useState(initialAppContext.isDetailSidebarCollapsed);
  const [theme, setTheme] = useState<Theme | undefined>(initialAppContext.theme);
  const [language, setLanguage] = useState<Language | undefined>(initialAppContext.language);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(initialAppContext.userInfo);
  const [userInfoQr, setUserInfoQr] = useState<IEmployee | undefined>(initialAppContext.userInfoQr);
  const [resources, setResources] = useState<IResourceFullAccessDetail[] | undefined>(initialAppContext.resources);
  const [roles, setRoles] = useState<IRole[] | undefined>(initialAppContext.roles);

  const [isUserAdmin, setIsUserAdmin] = useState<boolean | undefined>(initialAppContext.isUserAdmin);
  const [isOpenFilterDrawer, setIsOpenFilterDrawer] = useState<boolean>(initialAppContext.isOpenFilterDrawer);
  const [documentField, setDocumentField] = useState<IDocumentField | undefined>(initialAppContext.documentField);
  const [documentFieldLoading, setDocumentFieldLoading] = useState<boolean>(false);
  const [openTicketManagement, setOpenTicketManagement] = useState<boolean>(false);
  const [openAppMenu, setOpenAppMenu] = useState<boolean>(false);
  const [resourcePermissionLoading, setResourcePermissionLoading] = useState<boolean | undefined>(false);
  const [rootResources, setRootResources] = useState<ResourceShort[]>(initialAppContext.rootResources);
  const [rootResource, setRootResource] = useState<ResourceShort | undefined>(initialAppContext.rootResource);
  const [currentResource, setCurrentResource] = useState<ResourceShort | undefined>(initialAppContext.currentResource);
  const token = getAccessTokenFromLS();

  useQueries({
    queries: [
      {
        queryKey: ['webConfigs'],
        queryFn: async () => await getWebConfigs(),
        staleTime: 60 * 1000,
        enabled: !!token,
        onSuccess: (data: AxiosResponse<WebConfigAdd[], any>) => {
          if (data?.data && data?.data.length > 0) {
            const result = Object.fromEntries(data?.data.map((item) => [item.key, item.value]));
            setTheme(result[WebConfigEn.theme] as Theme);
            setLanguage(result[WebConfigEn.language] as Language);
          }
        }
      },
      {
        queryKey: ['checkAdmin'],
        queryFn: async () => await checkAdmin(),
        enabled: !!token,
        onSuccess: (data: AxiosResponse<boolean, any>) => {
          if (data.data) {
            localStorage.setItem('isAdmin', JSON.stringify(data.data));
          }
          setIsUserAdmin(data.data);
        }
      },
      {
        queryKey: ['bluePrintResources'],
        queryFn: async () => getResourcesByParent(undefined, undefined, true),
        enabled: !!token,
        onSuccess: (data: AxiosResponse<ResourceShort[]>) => {
          const hierarchyAppFn = buildHierarchy(data?.data);

          setRootResources(hierarchyAppFn || []);
        }
      }
    ]
  });

  useEffect(() => {
    i18n.language === 'en' ? i18n.changeLanguage('vi') : i18n.changeLanguage('en');
    if (i18n.language !== language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  return (
    <AppContext.Provider
      value={{
        ticketSelected,
        setTicketSelected,
        openTicketManagement,
        setOpenTicketManagement,
        openAppMenu,
        setOpenAppMenu,
        isSidebarCollapsed,
        setIsSidebarCollapsed,
        isDetailSidebarCollapsed,
        setIsDetailSidebarCollapsed,
        isTicketDetailCollapsed,
        setIsTicketDetailCollapsed,
        theme,
        setTheme,
        language,
        setLanguage,
        userInfo,
        setUserInfo,
        userInfoQr,
        setUserInfoQr,
        resources,
        setResources,
        resourcePermissionLoading,
        setResourcePermissionLoading,
        roles,
        setRoles,
        isUserAdmin,
        setIsUserAdmin,
        isOpenFilterDrawer,
        setIsOpenFilterDrawer,
        documentField,
        setDocumentField,
        documentFieldLoading,
        setDocumentFieldLoading,
        rootResource,
        setRootResource,
        rootResources,
        setRootResources,
        currentResource,
        setCurrentResource
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

import type { FlatItem } from 'src/types/elements.type';
import { DistanceAdd, IDistance } from 'src/types/master_data/distance.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'master-data/distances';

export const getDistances = async (
  page: number,
  pageSize: number,
  orderBy?: string,
  startingLocation?: string,
  destinationLocation?: string
) => {
  return await http.get<PagedList<IDistance>>(`${controller}`, {
    params: {
      page,
      pageSize,
      orderBy,
      startingLocation,
      destinationLocation
    }
  });
};

export const getDistance = async (id: number) => {
  return http.get<IDistance>(`${controller}/${id}`);
};

export const getDistanceAll = async () => {
  return http.get<IDistance[]>(`${controller}/get-all`);
};

export const addDistance = async (distance: DistanceAdd) => {
  return http.post<IDistance>(controller, distance);
};

export const updateDistance = async (id: number, distance: IDistance) => {
  return await http.put<IDistance[]>(`${controller}/${id}`, distance);
};

export const deleteDistance = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};

export const getDistancesDropdown = async () => {
  return await http.get<FlatItem[]>(`${controller}/dropdown`);
};
export const getDistancesBetweenLocation = async (
  startingLocationId?: string | number,
  destinationLocationId?: string | number
) => {
  return await http.get<IDistance>(`${controller}/distance-between-locations`, {
    params: { startingLocationId, destinationLocationId }
  });
};

import { lazy } from 'react';
import { PermissionEn } from 'src/constants/permission.enum';
import planningPath from 'src/constants/urlPath/erp/planningPath';
import type { RouteType } from 'src/hooks/useRouteElements';
import NewLayout from '../../layouts/NewFeatures/MainLayout';

const ProjectPlanning = lazy(() => import('src/pages/ProjectPlanning'));

const SaleOrder = lazy(() => import('src/pages/ERP/Sale/SaleOrder'));
const SaleOrderForm = lazy(() => import('src/pages/ERP/Sale/SaleOrder/AddSaleOrder'));

const planningRoutes: RouteType[] = [
  {
    path: planningPath.planning,
    Layout: NewLayout,
    Component: ProjectPlanning
  },
  // Sale order - Đơn bán hàng
  {
    path: planningPath.saleOrder,
    Layout: NewLayout,
    Component: SaleOrder,
    checkPath: planningPath.saleOrder,
    permission: PermissionEn.read
  },
  {
    path: `${planningPath.saleOrderDetailCode}/:code`,
    Layout: NewLayout,
    Component: SaleOrderForm,
    checkPath: planningPath.saleOrder,
    permission: PermissionEn.viewDetail
  }

  // ĐỀ NGHỊ CẤP HÀNG - SUPPLY REQUEST
  // {
  //   path: urlPath.sr.location,
  //   Layout: NewLayout,
  //   Component: SupplyRequest,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: urlPath.srAdd.location,
  //   Layout: NewLayout,

  //   Component: AddSupplyRequest,
  //   permission: PermissionEn.create
  // },
  // {
  //   path: `${urlPath.srEdit.location}/:id`,
  //   Layout: NewLayout,

  //   Component: AddSupplyRequest,
  //   permission: PermissionEn.update
  // },
  // {
  //   path: `${urlPath.srDetail.location}/:id`,
  //   Layout: NewLayout,
  //   Component: SupplyRequestDetail,
  //   permission: PermissionEn.viewDetail
  // },
  // {
  //   path: `${urlPath.srDetailCode.location}/:code`,
  //   Layout: NewLayout,
  //   Component: SupplyRequestDetail,
  //   permission: PermissionEn.viewDetail
  // },
  // {
  //   path: urlPath.companyBudget.location,
  //   Layout: NewLayout,
  //   Component: CompanyBudget,
  //   checkPath: urlPath.companyBudget.location,
  //   permission: PermissionEn.read
  // },
  // // ĐỀ NGHỊ CẤP HÀNG - SUPPLY REQUEST
  // {
  //   path: urlPath.sr.location,
  //   Layout: NewLayout,
  //   Component: SupplyRequest,
  //   checkPath: urlPath.sr.location,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: urlPath.srAdd.location,
  //   Layout: NewLayout,
  //   Component: AddSupplyRequest,
  //   checkPath: urlPath.sr.location,
  //   permission: PermissionEn.create
  // },
  // {
  //   path: `${urlPath.srEdit.location}/:id`,
  //   Layout: NewLayout,

  //   Component: AddSupplyRequest,
  //   checkPath: urlPath.sr.location,
  //   permission: PermissionEn.update
  // },
  // {
  //   path: `${urlPath.srDetail.location}/:id`,
  //   Layout: NewLayout,
  //   Component: SupplyRequestDetail,
  //   checkPath: urlPath.sr.location,
  //   permission: PermissionEn.viewDetail
  // },
  // {
  //   path: `${urlPath.srDetailCode.location}/:code`,
  //   Layout: NewLayout,
  //   Component: SupplyRequestDetail,
  //   checkPath: urlPath.sr.location,
  //   permission: PermissionEn.viewDetail
  // },
  // {
  //   path: urlPath.companyBudget.location,
  //   Layout: NewLayout,
  //   Component: CompanyBudget,
  //   checkPath: urlPath.companyBudget.location,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: urlPath.budgetControl.location,
  //   Layout: NewLayout,
  //   Component: BudgetControl,
  //   checkPath: urlPath.budgetControl.location,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: urlPath.costControl.location,
  //   Layout: NewLayout,
  //   Component: CostControl,
  //   checkPath: urlPath.costControl.location,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: urlPath.planningSrSummary.location,
  //   Layout: NewLayout,
  //   Component: SrSummary,
  //   checkPath: urlPath.planningSrSummary.location,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: `${urlPath.planningSrSummaryDetail.location}/:code`,
  //   Layout: NewLayout,
  //   Component: SrSummaryDetail,
  //   checkPath: urlPath.planningSrSummary.location,
  //   permission: PermissionEn.viewDetail
  // },
  // {
  //   path: urlPath.planningPrSummary.location,
  //   Layout: NewLayout,
  //   Component: PrSummary,
  //   checkPath: urlPath.planningPrSummary.location,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: `${urlPath.planningPrSummaryDetail.location}/:code`,
  //   Layout: NewLayout,
  //   Component: PrSummaryDetail,
  //   checkPath: urlPath.planningPrSummary.location,
  //   permission: PermissionEn.viewDetail
  // },
  // {
  //   path: urlPath.planningPQ.location,
  //   Layout: NewLayout,
  //   Component: PurchaseQuotation,
  //   checkPath: urlPath.planningPQ.location,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: urlPath.planningPR.location,
  //   Layout: NewLayout,
  //   Component: PurchaseRequest,
  //   checkPath: urlPath.planningPR.location,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: `${urlPath.planningPRDetailCode.location}/:code`,
  //   Layout: NewLayout,
  //   Component: PurchaseRequestDetail,
  //   checkPath: urlPath.planningPR.location,
  //   permission: PermissionEn.viewDetail
  // },
  // {
  //   path: urlPath.planningPRAdd.location,
  //   Layout: NewLayout,

  //   Component: AddPurchaseRequest,
  //   checkPath: urlPath.planningPR.location,
  //   permission: PermissionEn.create
  // },
  // {
  //   path: `${urlPath.planningPREdit.location}/:id`,
  //   Layout: NewLayout,

  //   Component: AddPurchaseRequest,
  //   checkPath: urlPath.planningPR.location,
  //   permission: PermissionEn.update
  // },
  // {
  //   path: urlPath.planningSaleOrder.location,
  //   Layout: NewLayout,
  //   Component: SO,
  //   checkPath: urlPath.planningSaleOrder.location,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: `${urlPath.planningSODetailCode.location}/:code`,
  //   Layout: NewLayout,

  //   Component: AddSO,
  //   checkPath: urlPath.planningSaleOrder.location,
  //   permission: PermissionEn.viewDetail
  // },
  // {
  //   path: urlPath.planningProductionOrder.location,
  //   Layout: NewLayout,
  //   Component: ProductionOrder,
  //   checkPath: urlPath.planningProductionOrder.location,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: urlPath.mrp.location,
  //   Layout: NewLayout,
  //   Component: MRP,
  //   checkPath: urlPath.mrp.location,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: urlPath.mrpReports.location,
  //   Layout: NewLayout,
  //   Component: MRPReport,
  //   checkPath: urlPath.mrpReports.location,
  //   permission: PermissionEn.read
  // },
];

export default planningRoutes;

import { CRUDPathSegmentEn } from '../../newFeatures/crudPathSegment.enum';

const planningPath = {
  planning: '/erp/planning',

  // Sale order - Đơn bán hàng
  saleOrder: '/erp/planning/so',
  saleOrderDetailCode: '/erp/planning/so' + CRUDPathSegmentEn.DETAIL_BY_CODE

  // GRPOR - Phiếu yêu cầu nhập kho
  //   inventoryGrpor: '/erp/inventory/grpor',
  //   inventoryGrporDetail: '/erp/inventory/grpor' + CRUDPathSegmentEn.DETAIL,
  //   inventoryGrporDetailCode: '/erp/inventory/grpor' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  //   inventoryGrporEdit: '/erp/inventory/grpor' + CRUDPathSegmentEn.EDIT
};

export default planningPath;

//  planningSaleOrder: getPath('/erp/planning/so', ['sidebar.home', 'v2.projectPlanning.index', 'v2.projectPlanning.so']),
// planningSODetailCode: getPath('/erp/planning/so' + CRUDPathSegmentEn.DETAIL_BY_CODE,

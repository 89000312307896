import { lazy } from 'react';
import { PermissionEn } from 'src/constants/permission.enum';
import salePath from 'src/constants/urlPath/erp/salePath';
import type { RouteType } from 'src/hooks/useRouteElements';
import NewLayout from '../../layouts/NewFeatures/MainLayout';

const Sale = lazy(() => import('src/pages/ERP/Sale'));

const SO = lazy(() => import('src/pages/ERP/Sale/SaleOrder'));
const AddSO = lazy(() => import('src/pages/ERP/Sale/SaleOrder/AddSaleOrder'));

const Opportunity = lazy(() => import('src/pages/ERP/Sale/Opportunity'));
const OpportunityForm = lazy(() => import('src/pages/ERP/Sale/Opportunity/OpportunityForm'));

const WorkAssign = lazy(() => import('src/pages/ERP/Planning/Kpi/WorkAssign'));

const saleRoutes: RouteType[] = [
  {
    path: salePath.sale,
    Layout: NewLayout,
    Component: Sale
  },
  // Sale order - Đơn bán hàng
  {
    path: salePath.saleOrder,
    Layout: NewLayout,
    Component: SO,
    checkPath: salePath.saleOrder,
    permission: PermissionEn.read
  },
  {
    path: salePath.saleOrderAdd,
    Layout: NewLayout,

    Component: AddSO,
    checkPath: salePath.saleOrder,
    permission: PermissionEn.create
  },
  {
    path: `${salePath.saleOrderEdit}/:code`,
    Layout: NewLayout,
    Component: AddSO,
    checkPath: salePath.saleOrder,
    permission: PermissionEn.update
  },
  {
    path: `${salePath.saleOrderDetailCode}/:code`,
    Layout: NewLayout,
    Component: AddSO,
    checkPath: salePath.saleOrder,
    permission: PermissionEn.viewDetail
  },
  // Opportunity - Cơ hội kinh doanh
  {
    path: salePath.opportunity,
    Layout: NewLayout,
    Component: Opportunity,
    checkPath: salePath.opportunity,
    permission: PermissionEn.read
  },
  {
    path: salePath.opportunityAdd,
    Layout: NewLayout,
    Component: OpportunityForm,
    checkPath: salePath.opportunity,
    permission: PermissionEn.create
  },
  {
    path: `${salePath.opportunityEdit}/:id`,
    Layout: NewLayout,
    Component: OpportunityForm,
    checkPath: salePath.opportunity,
    permission: PermissionEn.update
  },
  {
    path: `${salePath.opportunityDetail}/:id`,
    Layout: NewLayout,
    Component: OpportunityForm,
    checkPath: salePath.opportunity,
    permission: PermissionEn.viewDetail
  },

  // Work assign - Giao việc bán hàng
  {
    path: salePath.workAssign,
    Layout: NewLayout,
    Component: WorkAssign,
    checkPath: salePath.workAssign,
    permission: PermissionEn.read
  }
];

export default saleRoutes;

// {
//   path: urlPath.salePr.location,
//   Layout: NewLayout,
//   Component: PurchaseRequest,
//   checkPath: urlPath.salePr.location,
//   permission: PermissionEn.read
// },

import { QueryClient, UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { NavigateFunction } from 'react-router-dom';
import { DocxIcon, FlatFileIcon, PdfIcon, TxtIcon, XlsxIcon, ZipIcon } from 'src/assets/icons';
import { NotificationType } from 'src/components/v1/Header/Header';
import { FileTypeEn } from 'src/constants/fileType.enum';
import { NOTIFICATION_TYPE } from 'src/constants/notification';
import { PermissionEn } from 'src/constants/permission.enum';
import { TimeCompareEn } from 'src/constants/time';
import { EmoticonType } from 'src/types/emoji.type';
import { IFilePath } from 'src/types/helpdesk/topic.type';
import { IRole } from 'src/types/master_data/role.type';
import { IResourceFullAccessDetail } from 'src/types/master_data/userResource.type';
import { SearchParamType } from 'src/types/searchParams.type';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
dayjs.locale('vi'); // Thiết lập ngôn ngữ

export function getLastItem<T>(arr: T[]): T | undefined {
  if (arr.length === 0) {
    return undefined;
  }
  return arr[arr.length - 1];
}

export function getOrderString(field?: string, order?: string) {
  switch (order) {
    case 'ascend':
      return `${field} asc`;
    case 'descend':
      return `${field} desc`;
    default:
      return '';
  }
}

export function getOrderStringV3(field?: string, order?: string) {
  switch (order) {
    case 'ascend':
      return `${field} asc`;
    case 'descend':
      return `${field} desc`;
    default:
      return 'createdDate desc';
  }
}

export function getOrderStringV2(field?: string, order?: string) {
  return `${field || ''} ${order || ''}`;
}

export function getDateAfter(date?: Date, total: number = 0): string | undefined {
  if (date) {
    let nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + total);
    return dayjs(nextDate).format('YYYY-MM-DD');
  }
}

// Tạo một hàm để lấy tên ngày tắt
export const getShortDayName = (date: Date) => {
  const dayOfWeek = dayjs(date).day(); // 0: CN, 1: T2, ..., 6: T7
  const shortDayNames = ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'];
  return shortDayNames[dayOfWeek];
};

export function formatDate(date?: Date, isDaysInWeek?: boolean): string | undefined {
  if (date) {
    if (isDaysInWeek) {
      const shortDay = getShortDayName(date);
      return `${shortDay} ${dayjs(date).format('DD/MM/YYYY')}`;
    }
    return dayjs(date).format('DD/MM/YYYY');
  }
}

export function formatDateTime(date?: Date): string | undefined {
  if (date) return dayjs(date).format('DD/MM/YYYY HH:mm');
}

export function formatTime(date?: Date): string | undefined {
  if (date) return dayjs(date).format('HH:mm');
}

export function formatTimeSecond(date?: Date): string | undefined {
  if (date) return dayjs(date).format('HH:mm:ss');
}

export function formatTimeString(time?: string, isStart?: boolean): string | undefined {
  if (time) {
    const timeArr = time.split(':');
    if (timeArr.length >= 3) {
      timeArr.pop();
      return isStart ? timeArr.join(':') : timeArr.join(':').replace('00:00', '24:00');
    }
    return isStart ? time : time.replace('00:00', '24:00');
  }
}

export function checkConsecutive(arr: number[]): number | undefined {
  arr.sort((a, b) => a - b);

  for (let i = 1; i < arr.length; i++) {
    if (arr[i] !== arr[i - 1] + 1) {
      return arr[i];
    }
  }
}

export function formatToVND(num: number | string) {
  return Math.round(Number(num))
    ?.toString()
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getPathsFromRoles(roles: IRole[] | undefined) {
  let result: string[] = [];
  Array.isArray(roles) &&
    roles?.forEach((item) => {
      item.roleResources.forEach((item) => {
        if (item.resource?.path && !result?.includes(item.resource?.path)) {
          result.push(item.resource?.path.trim());
        }
      });
    });

  return result;
}

export function getResourceFromRoles(roles: IRole[] | undefined) {
  let result: { [key: string]: string[] } = {};

  roles?.forEach((item, index) => {
    item.roleResources.forEach((item) => {
      if (item.resource?.path) {
        const permissions: string[] = [];
        item.roleResourcePermissions.forEach((roleRes) => {
          if (item.resource?.path && roleRes.permission?.code) {
            if (result[item.resource?.path]?.length > 0) {
              if (result[item.resource?.path]?.includes(roleRes.permission?.code)) {
                return;
              } else {
                result[item.resource?.path].push(roleRes.permission?.code);
              }
            } else {
              permissions?.push(roleRes.permission?.code);
              result[item.resource?.path] = permissions;
            }
          }
        });
      }
    });
  });

  return result;
}

export function hasPermission(path: string, permission: PermissionEn, resources: IResourceFullAccessDetail[]): boolean {
  const resource = resources?.find((x) => x.path === path);
  const result = resource?.permissions?.includes(permission);
  // const result = `/${path?.split('/')[2]}`;
  return !!result;
}

export function getListPermission(path: string, roles: IRole[] | undefined): string[] {
  const result = `/${path?.split('/')[2]}`;
  const resource = getResourceFromRoles(roles);
  return resource[result];
}

// export function getListPermissionV2(path: string, roles: IRole[] | undefined): string[] {
//   const resource = getResourceFromRoles(roles);
//   return resource[path];
// }

export function handleInvalidate(key: string, queryClient: QueryClient) {
  const [statusTicket] = NOTIFICATION_TYPE.filter((item) => item.key === key);
  if (Array.isArray(statusTicket.queryFn)) {
    statusTicket.queryFn.forEach((item) => {
      queryClient.invalidateQueries({
        queryKey: [item]
      });
    });
  } else {
    queryClient.invalidateQueries({
      queryKey: [statusTicket.queryFn]
    });
  }
}

export function handleNavigate(
  item: NotificationType,
  markNotificationsAsReadMutation: UseMutationResult<AxiosResponse<any, any>, unknown, string | undefined, unknown>,
  navigate: NavigateFunction
) {
  !item.isRead && markNotificationsAsReadMutation.mutate(item.id);
  const obj = NOTIFICATION_TYPE.find((obj) => obj.key === item.type);
  if (obj?.key === 'TKSAD') {
    const id = item.title.split('-')[1].trim();
    return navigate(`${obj?.returnUrl}/${id}`);
  } else {
    return navigate(obj?.returnUrl ?? '');
  }
}

export function removeVietnameseTones(str: string) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str.replace(/Đ/g, 'D');
  str = str.replace(/\u02C6|\u0306|\u031B/g, '');
  str = str.replace(/ + /g, ' ');
  str = str.trim();
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\\=|\\<|\\>|\?|\/|,|\.|\\:|\\;|\\'|\\"|\\&|\\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    ' '
  );
  return str;
}

export function camelCaseToNormal(text: string): string {
  return text.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (str) => str.toUpperCase());
}

export function checkIsPhoneNumber(str: string): boolean {
  const vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g;
  return vnf_regex.test(str);
}

export function checkIsEmail(str: string): boolean {
  const email_regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email_regex.test(str);
}

export function checkIsIP(str: string): boolean {
  const ip_regex =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return ip_regex.test(str);
}

export function base64ToArrayBuffer(base64: string) {
  const binaryString = atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

export const totalOfRangeDate = (fromDate?: Date, toDate?: Date) => {
  const fDate = new Date(dayjs(fromDate).format('YYYY-MM-DD'));
  const tDate = new Date(dayjs(toDate).format('YYYY-MM-DD'));

  const dates: string[] = [];
  const currentDate = fDate;

  while (fDate <= tDate) {
    const currentStringDate = dayjs(currentDate).format('YYYY-MM-DD');
    if (!dates?.includes(currentStringDate)) {
      dates.push(currentStringDate);
    }

    if (currentDate.getDate() === 1 && fDate.getDate() !== 1) {
      currentDate.setMonth(currentDate.getMonth() + 1);
      currentDate.setDate(1);
    } else currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates.length;
};

export const totalOfRangeDateV2 = (fromDate?: Date, toDate?: Date): number => {
  if (!fromDate || !toDate) return 0;

  const fromDateFormat = Date.parse(dayjs(fromDate).format('YYYY-MM-DD'));
  const toDateFormat = Date.parse(dayjs(toDate).format('YYYY-MM-DD'));

  return (toDateFormat - fromDateFormat) / (1000 * 60 * 60 * 24) + 1;
};

export const totalOfRangeTime = (startTimeInput?: string, endTimeInput?: string) => {
  const startTime = new Date(dayjs(startTimeInput, 'HH:mm').format('YYYY-MM-DD HH:mm:ss')).getTime() || 0;
  const endTime = new Date(dayjs(endTimeInput, 'HH:mm').format('YYYY-MM-DD HH:mm:ss')).getTime() || 0;

  return (endTime - startTime) / (3600 * 1000);
};

export const convertTimeToNumber = (timeInput?: string) => {
  const time = new Date(dayjs(timeInput, 'HH:mm').format('YYYY-MM-DD HH:mm:ss')).getTime() || 0;

  return time / (3600 * 1000);
};

export const convertMinutesToHours = (minutes?: number) => {
  if (!minutes) {
    return 0;
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (hours && remainingMinutes) {
    return `${hours} tiếng ${remainingMinutes} phút`;
  } else if (hours) {
    return `${hours} tiếng`;
  } else if (remainingMinutes) {
    return `${remainingMinutes} phút`;
  }

  return 0;
};

export const convertMinutesToDecimalHours = (minutes?: number) => {
  if (!minutes) return 0;

  const roundedHours = Math.round((minutes / 60) * 10) / 10;
  return roundedHours;
};

export const formatTitle = (str: string) => {
  const newStr = removeVietnameseTones(str).split(' ').join('_');
  return newStr.toLocaleLowerCase();
};

export const getPathMain = (url: string): string => {
  return url.split('/').splice(0, 3).join('/');
};

export const getBase64 = (file: Blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return reader.result;
};

export function blobToBase64(blob: Blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export function readFile(file: Blob, fileType: string) {
  const url = window.URL.createObjectURL(
    new Blob([file], {
      type: getContentType(fileType)
    })
  );
  window.open(url, '_blank');
}

export const getContentType = (type: string) => {
  return type + '; charset=utf-8;';
};

export const scrollToElement = (
  elementId: string,
  position?: 'center' | 'end' | 'nearest' | 'start',
  behavior?: ScrollBehavior
) => {
  let element = document.getElementById(elementId) as Element;

  element &&
    element.scrollIntoView({
      behavior: 'auto',
      block: position || 'start',
      inline: 'nearest'
    });
};
export const scrollToBottom = () => {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: 'smooth'
  });
};
export const scrollToFirstError = (err: any) => {
  const errName = err.errorFields?.[0]?.name?.[0];
  let element = document.querySelector(`[for="${errName}"]`) as Element;

  element &&
    element.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'nearest'
    });
};

export const scrollToReply = (elementId: string, position?: 'center' | 'end' | 'nearest' | 'start') => {
  let element = document.getElementById(elementId) as HTMLDivElement;

  element &&
    element.scrollIntoView({
      behavior: 'auto',
      block: position || 'start',
      inline: 'nearest'
    });

  element.style.animation = 'zoom-in-zoom-out 2s ease';
  setTimeout(() => {
    element.style.animation = 'unset';
  }, 3000);
};

export const getFirstLetters = (text: string) => {
  const str = text;
  const matches = str?.split(' ')?.map((word) => word.charAt(0));
  const acronym = (matches?.length > 1 ? matches?.[matches?.length - 2] : '') + matches?.[matches?.length - 1];
  return acronym;
};

export const getLastname = (text: string) => {
  if (text) {
    const str = text;
    const matches = str?.split(' ');
    const lastname = matches?.[matches?.length - 1];
    return lastname;
  }
  return '';
};

export const convertMessionInput = (text: string) => {
  if (!!text) {
    let regex = /@\[.+?\]\(.+?\)/gm;
    let displayRegex = /@\[.+?\]/g;
    let idRegex = /\(.+?\)/g;
    let matches = text?.match(regex);
    let arr: any[] = [];
    matches &&
      matches.forEach((m) => {
        let id = m.match(idRegex)?.[0].replace('(', '').replace(')', '');
        let display = m.match(displayRegex)?.[0].replace('@[', '').replace(']', '');

        arr.push({ id: id, display: display });
      });
    let newComment = text?.split(regex);
    let output = '';
    for (let i = 0; i < newComment.length; i++) {
      const c = newComment[i];
      if (i === newComment.length - 1) output += c;
      else output += c + `<a href="#" style="font-size: inherit;">@${arr[i].display}</a>`;
    }
    return output;
  }
  return '';
};

export function convertToTimeZoneWithoutOffset(dateString: string | Date): Date {
  const date = dateString instanceof Date ? dateString : new Date(dateString);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const dateWithoutOffset = new Date(date.getTime() - userTimezoneOffset);

  return dateWithoutOffset;
}

export function getExtension(filename: string) {
  const parts = filename?.split('.');
  return parts?.[parts.length - 1];
}

export const scrollToNewMessage = (behavior?: ScrollBehavior | undefined) => {
  let element = document.querySelector('#topic-conversation-content') as HTMLDivElement;

  element &&
    element.scrollTo({
      behavior: behavior ?? 'auto',
      top: 0,
      left: 0
    });
};

export function isImage(filename: string) {
  const ext = getExtension(filename);
  switch (ext?.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'bmp':
    case 'png':
    case 'svg':
      //etc
      return true;
  }
  return false;
}

export function isVideo(filename: string) {
  const ext = getExtension(filename);
  switch (ext?.toLowerCase()) {
    case 'm4v':
    case 'avi':
    case 'mpg':
    case 'mp4':
      // etc
      return true;
  }
  return false;
}

export const getFilePreview = (filename: string) => {
  const ext = getExtension(filename);
  switch (ext?.toLowerCase()) {
    case FileTypeEn.Pdf:
      return PdfIcon;
    case FileTypeEn.Word:
      return DocxIcon;
    case FileTypeEn.Doc:
      return DocxIcon;
    case FileTypeEn.Excel:
      return XlsxIcon;
    case FileTypeEn.Notepad:
      return TxtIcon;
    case FileTypeEn.Zip:
      return ZipIcon;
    default:
      return FlatFileIcon;
  }
};

export const groupByAttachment = (attachments: IFilePath[]) => {
  const result: { images: IFilePath[]; files: IFilePath[] } = {
    images: [],
    files: []
  };

  attachments.forEach((attachment) => {
    if (isImage(attachment.title) || isVideo(attachment.title)) {
      result.images?.push(attachment);
    } else {
      result.files?.push(attachment);
    }
  });
  return result;
};

export function linkify(text: string) {
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
  return text.replace(urlRegex, function (url) {
    return '<a href="' + url + '">' + url + '</a>';
  });
}

export function isLink(text: string) {
  // eslint-disable-next-line no-control-regex
  const urlRegex = new RegExp('/(\b(https?|ftp|file)://[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi');

  return urlRegex.test(text);
}

//  Kiểm tra thời gian cách nhau có dưới 1 tiếng và cùng 1 người hay không?
export function hasUnderOneHourAndSamePerson({
  previousDate,
  previousSenderCode,
  currentDate,
  currentSenderCode
}: {
  previousDate: Date;
  previousSenderCode: string;
  currentDate: Date;
  currentSenderCode: string;
}) {
  const currentMessageDate = dayjs(currentDate).format('YYYY-MM-DD HH:mm:ss');
  const previousMessageDate = dayjs(previousDate).format('YYYY-MM-DD HH:mm:ss');

  const timeDifference = (Date.parse(currentMessageDate) - Date.parse(previousMessageDate)) / (1000 * 60 * 60);

  if (timeDifference <= 1 && !!previousSenderCode && !!currentSenderCode && previousSenderCode === currentSenderCode) {
    return true;
  } else {
    return false;
  }
}

export function hasHour(messageDate: Date): boolean {
  const nowDate = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');

  const timeDifference =
    (Date.parse(nowDate) - Date.parse(dayjs(messageDate).format('YYYY-MM-DD HH:mm:ss'))) / (1000 * 60 * 60);

  if (timeDifference < 1) {
    return true;
  } else {
    return false;
  }
}

export function convertToEmoji(str: string, newData: EmoticonType): string {
  const listLetter: string[] = str?.split(' ');
  const convertListLetter: string[] = listLetter;

  listLetter?.forEach((item, index) => {
    if (item in newData) {
      convertListLetter[index] = newData[`${item}`];
    }
  });

  return convertListLetter?.join(' ');
}

export function focusInput(elementId: string) {
  const element = document.getElementById(elementId);
  if (element) {
    element.focus();
  }
}

export function uppercaseFirstLetter(text: string) {
  if (text && text.length > 0) {
    return text[0].toUpperCase() + text.slice(1);
  } else return text;
}

export const getParamItem = (key: string, value: any) => {
  return { key: key, value: value } as SearchParamType;
};

export const groupBy = (xs: any[], key: string) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const findLongestArr = (lengths: number[]) => {
  return lengths.indexOf(Math.max(...lengths));
};

export const getRandomFromObject = (obj: Object) => {
  const keyArr = Object.keys(obj);
  return keyArr[Math.floor(Math.random() * keyArr.length)];
};

export const getAddPath = (path?: string) => {
  const pathSplit = path?.split('/');
  return pathSplit?.[pathSplit?.length - 1];
};

export const get24Hours = () => {
  return Array.from({ length: 24 }, (_, i) => i);
};
export const get60Minutes = () => {
  return Array.from({ length: 59 }, (_, i) => i + 1);
};

export const getCountDaysInMonth = (month: number, year: number, returnType: 'number' | 'array' = 'number') => {
  const count = new Date(year, month, 0).getDate();
  if (returnType === 'number') {
    return count;
  } else {
    const days = [...[...Array(count - 19).keys()].map((x) => x + 20), ...[...Array(19).keys()].map((x) => x + 1)];
    return days;
  }
};

export const isJsonType = (data: string | null) => {
  if (!data) {
    return false;
  }

  try {
    JSON.parse(data);
    return true;
  } catch (e) {
    return false;
  }
};

export const compareTimeString = (time1: string, time2: string) => {
  const format = 'HH:mm';
  if (dayjs(time1.substring(0, 5), format).isAfter(dayjs(time2.substring(0, 5), format))) {
    return TimeCompareEn.GREATER;
  } else if (dayjs(time1.substring(0, 5), format).isBefore(dayjs(time2.substring(0, 5), format))) {
    return TimeCompareEn.LESS;
  } else {
    return TimeCompareEn.EQUAL;
  }
};

export const convertTimeFormat = (time: string) => {
  // Kiểm tra định dạng thời gian đầu vào
  const regex = /^(\d{2}):(\d{2}):(\d{2})$/;
  const match = time.match(regex);

  if (match) {
    // Lấy giờ và phút từ chuỗi thời gian
    const hours = match[1];
    const minutes = match[2];

    // Trả về định dạng mới "HH:MM"
    return `${hours}:${minutes}`;
  } else {
    // Nếu không khớp với định dạng, trả về chuỗi gốc
    return time;
  }
};

export const getDateAt = (dayNumber: number) => {
  // Nếu truyền số âm thì lấy lùi ngày

  return new Date(dayjs(dayjs().add(dayNumber, 'day')).format('YYYY-MM-DD'));
};

export const countDayFromTwoDate = (startDate: Date, endDate: Date) => {
  // Tính toán độ chênh lệch giữa hai ngày
  const differenceInTime = endDate.getTime() - startDate.getTime();

  // Chuyển đổi độ chênh lệch sang ngày
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  return differenceInDays;
};

export const convertRGBToHexColor = (r: number, g: number, b: number) => {
  const toHex = (n: number) => {
    const hex = n.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};
